import React from 'react';
import { useState } from 'react';
import { Link } from '@reach/router';
import {
  Box,
  ButtonBase,
  Grid,
  Slide,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ImageCard from './image-card';

const styling = (theme) => ({
  banner: {
    backgroundColor: 'rgba(44, 44, 44, 0.7)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  bannerText: {
    color: theme.palette.common.white,
    fontSize: 20,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  description: (props) => ({
    paddingTop: 8,
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      paddingLeft: props.descriptionPLeft,
      paddingTop: 0,
      paddingRight: props.descriptionPRight,
    },
  }),
  img: {
    maxWidth: theme.breakpoints.md,
    position: 'relative',
  },
  root: {
    paddingTop: 12,
    paddingBottom: 12,
  },
});

/**
 * TODO:
 * - Bug: The slide transition appears for a brief second when hovering over a
 *   showcase when the image bottom is not visible (i.e. under the scroll)
 */
const GameShowcase = ({ banner, description, gameName, imgsrc, path, reverse }) => {
  // Styling/Theming
  const classes = makeStyles(styling)({
    descriptionPLeft: reverse ? 0 : 8,
    descriptionPRight: reverse ? 8 : 0,
  });

  // State
  const [isHovering, setIsHovering] = useState(false);

  // Handlers
  const handleHover = () => {
    setIsHovering(true);
  }

  const handleHoverExit = () => {
    setIsHovering(false);
  }

  return (
    <Grid
      container
      className={classes.root}
      direction={reverse ? "row-reverse" : "row"}
    >
      <Grid item xs={12} md={6}>
        <ButtonBase
          component={Link}
          disableRipple
          to={path}
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverExit
        }>
          <ImageCard
            className={classes.img}
            src={imgsrc}
          >
            <Slide direction="up" in={isHovering}>
              <Box>
                {banner &&
                <Box className={classes.banner}>
                  <Typography className={classes.bannerText}>
                    {`Learn more about ${gameName}`}
                  </Typography>
                </Box>
                }
              </Box>
            </Slide>
          </ImageCard>
        </ButtonBase>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography className={classes.description}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

GameShowcase.propTypes = {
  banner: PropTypes.bool,
  description: PropTypes.string.isRequired,
  gameName: PropTypes.string.isRequired,
  imgsrc: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  reverse: PropTypes.string,
}

GameShowcase.defaultProps = {
  reverse: false,
}

export default GameShowcase;
