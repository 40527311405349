import React from 'react';
import {
  Container,
} from '@material-ui/core';
import GameShowcase from '../components/game-showcase';
import Layout from '../components/layout';

const Games = {
  crabs: {
    banner: true,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ullamcorper purus nec ex posuere porta. Integer nec ipsum nulla. Morbi mattis ex sit amet justo pharetra, eu faucibus quam fermentum. Sed commodo luctus lacus ac dictum. Cras eget mi sit amet augue molestie malesuada. Ut tincidunt condimentum leo. Phasellus tempor tempor enim. Suspendisse enim nisi, suscipit non mauris faucibus, egestas placerat lectus. Praesent nec pellentesque erat, eget tristique tortor. Quisque laoreet justo id ligula commodo, sed molestie mi mollis.",
    imgsrc: "https://i.redd.it/c3uhsgo1vx541.jpg",
    name: "Crabs in a Bucket",
    path: "/games/crabs"
  },
  crabs2: {
    banner: true,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ullamcorper purus nec ex posuere porta. Integer nec ipsum nulla. Morbi mattis ex sit amet justo pharetra, eu faucibus quam fermentum. Sed commodo luctus lacus ac dictum. Cras eget mi sit amet augue molestie malesuada. Ut tincidunt condimentum leo. Phasellus tempor tempor enim. Suspendisse enim nisi, suscipit non mauris faucibus, egestas placerat lectus. Praesent nec pellentesque erat, eget tristique tortor. Quisque laoreet justo id ligula commodo, sed molestie mi mollis.",
    imgsrc: "https://i.redd.it/c3uhsgo1vx541.jpg",
    name: "Crabs in a Bucket",
    path: "/games/crabs"
  },
}

const GamesPage = () => {
  return(
    <Layout title="Games">
      <Container>
        {Object.keys(Games).map((game, index) => (
        <GameShowcase
          banner={Games[game].banner}
          description={Games[game].description}
          gameName={Games[game].name}
          imgsrc={Games[game].imgsrc}
          key={game}
          path={Games[game].path}
          reverse={(index % 2)}
        />
      ))}
      </Container>
    </Layout>
  );
}

export default GamesPage
